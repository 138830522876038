/* start tag */
.tag-tabs>.ant-tabs-nav>.ant-tabs-nav-wrap>.ant-tabs-nav-list {
  background: transparent;
}

.tag-tabs .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 3rem;
}

.tag-tabs .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 3rem;
  font-weight: 500;
  font-size: 20px !important;
}

/* end tag */
/* start card tag */
.tag-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.04) 0%,
      rgba(255, 255, 255, 0) 468.03%);
  backdrop-filter: blur(5px);
  height: 17rem;
}

.tag-classic-bg {
  margin-top: 0.5rem;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.06);
}

.edit-tag-member-input {
  border-radius: 7px;
  width: 11rem;
  background: #19132a;
  border: none !important;
}

.add-tag-btn {
  width: 100%;
  background: transparent;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.add-tag-btn:hover {
  background: transparent !important;
}

.edit-tag-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(180deg,
      rgba(215, 54, 209, 0.21) 0%,
      rgba(255, 255, 255, 0) 468.03%);
  backdrop-filter: blur(5px);

  height: 17rem;
}

.tag-card-head {
  padding: 16px 13px 13px;
  color: #fff;
  background: rgba(255, 255, 255, 0.06);
}

.tag-card-head-title {
  font-size: 15px !important;
}

.tag-card-head-sub-title {
  font-size: 10px !important;
  color: #848484 !important;
}

.tag-card-head-icon {
  font-size: 20px !important;
}

.tag-card-member-list {
  margin: 10px 0px;
  color: #fff;
  padding: 0px 1rem;
}

.tag-card-list {
  margin: 0px !important;
  padding: 0rem 0rem 0.6rem 0rem !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}

.tag-card-footer {
  padding: 0.6rem 0rem;
  border-top: 1px solid #696969;
}

.tag-card-footer-label {
  color: #555562;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
}

.tag-card-footer-view-all {
  color: #d736d1;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  text-decoration-line: underline;
  cursor: pointer;
}

.card-tag-add-icon {
  color: #fff;
  font-size: 4rem;
}

.tag-card-icon-center {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.add-tag-label {
  color: #fff;
  cursor: pointer;
  margin: 0.67rem 0rem;
  font-size: 16px;
  font-weight: 600;
}

.add-tag-label-delete {
  color: #ee3636;
  cursor: pointer;
  margin: 0.67rem 0rem;
  font-size: 16px;
  font-weight: 600;
}

.tag-center {
  text-align: center;
}

.edit-tag-list {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0rem;
}

.tag-card-list-scroll {
  height: 10rem;
  overflow: auto;
}

.tag-card-list-scroll::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.tag-card-list-scroll::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 3px #D9E9FF;
    background-color: #D9E9FF; */
  background-color: #17193a;
  border-radius: 0.5rem;
}

.tag-card-list-scroll::-webkit-scrollbar-thumb {
  background-color: #9a9a9a;
  border-radius: 10px;
  border: 2px solid #17193a;
}

.edit-tag-close-icon {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 18px;
  padding: 0px 4px 2px;
  cursor: pointer;
}

.edit-tag-add-input {
  background: transparent;
  border: none;
}

.pop-members-list {
  margin: 0.3rem;
  text-align: center;
}

/* model */
.tag-card-members-list .ant-modal-content {
  margin: 0rem !important;
  padding: 0rem !important;
  border: 1px solid #555562 !important;
  background: #07081b !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}

.tag-card-members-list .ant-modal-title {
  border-radius: 8px 8px 0px 0px;
  background: rgb(22 23 40) !important;
  padding: 1rem;
  text-align: center;
}

.tag-card-members-list .anticon-close {
  color: #fff;
}

/* model */
/* end card tag */
/* New design*/
.recent-body {
  /* background-color: #17193A; */
  background: #10112C;
  padding: 4rem;
  min-height: 100vh !important;
  height: auto !important;
}

.ant-popconfirm-title {
  color: #FFF !important;
}

.aivolvex-tag-input.ant-select-single .ant-select-selector {
  color: #fff !important;
}