.costimize-bootstrap-table {
  background: linear-gradient(to top,
      transparent,
      rgb(255, 255, 255),
      rgb(255, 255, 255));
  border-radius: 10px;
  padding: 0.6px;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  border: 0.2px solid transparent;
  border-bottom: none !important;
  height: 59% !important;


}

/* .costimize-bootstrap-table:after {
  content: " ";
  position: absolute;
  border-left: 1px #6c757d solid;
  top: 35%;
  right: 0;
  height: 30%;
  margin-top: auto;
  margin-bottom: auto;
} */



.costimize-bootstrap-table thead th {
  background-color: #ffffff0f !important;
  color: #fff !important;
  border: none !important;
  /* border-radius: 8px; */
  /* border-top:2px solid white !important;; */
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  text-align: center !important;
  z-index: 0 !important;
}

.costimize-bootstrap-table .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  border-radius: 0px 4dvb 4px 0px;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
}

.costimize-bootstrap-table .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  /* top-left top-right bottom-right bottom-left */
  border-radius: 4px 0px 0px 4px;
  color: #fff !important;
  border: 1px solid #fff !important;
  border-radius: 0px !important;
}

.costimize-bootstrap-table .ant-pagination .ant-pagination-item {
  margin: 0px;
  border-radius: 0px !important;
  border: 1px solid #fff !important;
}

li.ant-pagination-prev.ant-pagination-disabled {
  margin: 0px;
}

.costimize-bootstrap-table .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px !important;
  margin-right: 16px !important;
}

.costimize-bootstrap-table .ant-pagination .ant-pagination-item-active {
  background: rgba(255, 255, 255, 0.5) !important;
  color: #fff !important;
}

.costimize-bootstrap-table .anticon {
  display: flex !important;
  justify-content: center !important;
}

li.ant-pagination-item {
  color: #fff;
  border: 1px solid #fff !important;
}

.costimize-bootstrap-table .ant-table {
  /* background: transparent !important; */
  background: #17193a;
  /* background-color: #ffffff00 !important; */
  color: #fff;
}

.costimize-bootstrap-table tbody tr td {
  /* border-bottom: 1px solid #999; */
  border: none;
  /* border-bottom: 1px solid #848484 !important; */
  border-bottom: 0.563px solid #848484 !important;
}

.costimize-bootstrap-table .ant-table-cell-row-hover {
  background: transparent !important;
}

.ant-empty-description {
  color: #fff !important;
}

tr.ant-table-placeholder {
  background: transparent !important;
}

tr.ant-table-placeholder:hover {
  background: transparent !important;
}

li.ant-pagination-item {
  color: #fff;
}

.ant-pagination-item-active {
  color: #000 !important;
}

.recent-prompt-table-head {
  margin-top: 0.5rem;
  padding: 5px;
  display: flex;
  justify-content: center;
}

.recent-prompt-table {
  padding: 5px;
  display: flex;
  justify-content: center;
}

.recent-body {
  /* background-color: #17193A; */
  padding: 4rem;
  min-height: 100vh !important;
  height: auto !important;
}

:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-txh9fw).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  height: 0px !important;
}

.his-button {
  background: #3a3d63;
  border-radius: 4px;
  color: #fff;
  border: none;
}

.his-delete {
  background: #fb4b4b;
}

.his-delete:hover {
  background: #fb4b4b !important;
  color: #fff !important;
}

.his-delete-text {
  padding-left: 5px;
}

.history_btn {
  width: 40px;
  padding: 0px;
}

.his-button:hover {
  color: #fff !important;
  background: #3a3d63 !important;
}

.costimize-bootstrap-table .ant-select-selector {
  display: none !important;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
  color: #fff !important;
}

.ant-pagination-item-ellipsis {
  color: #fff !important;
}

.history-search {
  display: flex !important;
  justify-content: right !important;
}

.history-search.ant-input-affix-wrapper>input.ant-input {
  background: transparent !important;
}

.ant-input-affix-wrapper>input.ant-input {
  background: transparent !important;
}

.his-col {
  width: 25px !important;
}