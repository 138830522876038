.table-input {
    margin-top: 5px;
    padding: 5px;
    width: 100% !important;
    background: transparent !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    color: #FFF !important;
    border: none !important;
}

.his-button {
    background: #3A3D63;
    border-radius: 4px;
    color: #fff;
    border: none;
}

.his-delete {
    background: #FB4B4B;
    border-radius: 4px;
    color: #fff;
    border: none;
}

.his-delete-text {
    padding-left: 5px;
}

.history_btn {
    width: 40px;
    padding: 0px;
}

.his-delete:hover {
    background: #fb4b4b !important;
    color: #fff !important;
}

.costimize-bootstrap-table .ant-select-selector {
    display: none !important;
}

.ant-pagination-jump-next.ant-pagination-jump-next-custom-icon {
    color: #FFF !important
}

.ant-pagination-item-ellipsis {
    color: #FFF !important
}

.history-search {
    display: flex !important;
    justify-content: right !important;
}

.history-search.ant-input-affix-wrapper>input.ant-input {
    background: transparent !important;
}

.ant-input-affix-wrapper>input.ant-input {
    background: transparent !important;
}

.history-delete-modal .ant-btn-default {
    background: #3A3D63 !important;
    border: none !important;
    color: #fff !important;

}

.history-delete-modal .ant-btn-primary {
    color: #fff !important;
    background-color: #D736D1 !important;
    border: none !important;
}

.edit-button .ant-btn-default {
    background: #3A3D63 !important;
    border: none !important;
    color: #fff !important;

}

.edit-button .ant-btn-primary {
    color: #fff !important;
    background-color: #D736D1 !important;
    border: none !important;
}